<template>
  <section>
    <header class="mb-2">
      <b-card>
        <b-row>
          <b-col md="2" sm="12">
            <p><strong>Tanggal</strong></p>
            <p class="mb-2">{{ humanDate(pb.tanggal) }}</p>
          </b-col>
          <b-col lg="4" md="8" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ pb.jenis ? pb.jenis : "-" }}</p>
          </b-col>
          <b-col lg="2" md="4" sm="12">
            <p><strong>Status</strong></p>
            <p><b-badge v-if="pb.status == 'Belum Selesai' || pb.status == 'Belum selesai'" variant="light-danger">{{ pb.status }}</b-badge></p>
            <p><b-badge v-if="pb.status == 'Selesai'" variant="light-success">{{ pb.status }}</b-badge></p>
            <p><b-badge v-if="pb.status == 'Diketahui'" variant="light-success">{{ pb.status }}</b-badge></p>
          </b-col>
          <b-col lg="2" md="4" sm="12" >
            <p><strong>Status Owner</strong></p>
            <p><b-badge v-if="pb.mengetahui == 0 && pb.tipe == 2" variant="light-danger">Belum Approve</b-badge></p>
            <p><b-badge v-if="pb.mengetahui == 1 || pb.tipe == 1" variant="light-success">Approve</b-badge></p>
          </b-col>
          <b-col lg="2" md="4" sm="12" >
            <p><strong>Status Finance</strong></p>
            <p><b-badge v-if="pb.id_akun == null || pb.id_akun == 0" variant="light-danger">Belum Pilih Akun</b-badge></p>
            <p><b-badge v-if="pb.id_akun > 0" variant="light-success">Sudah Pilih Akun</b-badge></p>
          </b-col>
          <b-col sm="12" md="6" >
            <b-button class="mr-1" v-if="pb.checkout != 1 && !isFinance" size="sm" variant="success" @click="confirmselesai()"
              >Checkout</b-button
            >
            <b-button class="mr-1" v-if="pb.checkout != 0 && !isFinance && pb.id_akun == null && pb.tipe == 2" size="sm" variant="danger" @click="confirmcancel()"
              >Cancel Checkout</b-button
            >
            <b-button v-if="pb.mengetahui != 1 && isOwner && pb.tipe == 2" size="sm" variant="relief-success" @click="confirmapprove()"
              >Approve</b-button
            >
            <b-button v-if="pb.mengetahui != 0 && isOwner && pb.id_akun == null && pb.tipe > 1" size="sm" variant="relief-danger" @click="confirmcancelapprove()"
              >Cancel Approve</b-button
            >
            <b-button v-if="isFinance && (pb.mengetahui == 1 || pb.tipe == 1) && (pb.id_akun == null || pb.id_akun == 0)" size="sm" variant="info" @click="confirmdiberikan()"
              >Pilih Sumber Pengeluaran</b-button
            >
            <b-button v-if="isFinance && (pb.mengetahui == 1 || pb.tipe == 1) && pb.id_akun > 0" size="sm" variant="outline-danger" @click="confirmcanceljurnal()"
              >Cancel Akun</b-button
            >
          </b-col>
          <b-col sm="12" md="6" class="d-flex align-items-center justify-content-end">
            <p class="mr-1">Total</p><h4 class="text-danger text-right"><strong>Rp {{ formatRupiah(pb.total) }}</strong></h4>
          </b-col>

          <b-col cols="12">
            <b-modal
              id="modal-select2"
              v-model="showModalapprfinance"
              title="Pilih Akun dan Kas untuk Barang Entertain"
              ok-title="Tutup"
              ok-variant="secondary"
              ok-only
              centered
              no-close-on-backdrop
              no-close-on-esc
            >
              <b-form>
                <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                  <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" class="mb-1" />
                </b-form-group>
                <b-form-group label="Pilih Kas" label-for="vue-select">
                  <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" class="mb-1" />
                  <i class="text-danger" v-if="invalidKas">Saldo Kas kurang</i>
                </b-form-group>
              </b-form>
  
              <template #modal-footer>
                <b-button
                  :disabled="!isValidAkunForm"
                  type="submit"
                  @click.prevent="diberikan"
                  variant="primary"
                  class="mr-1"
                >
                  Simpan
                </b-button>
              </template>
            </b-modal>
          </b-col>
        </b-row>
      </b-card>
    </header>
    <main>
      <b-card action-collapse title="Daftar Ajuan Barang Entertain">
        <b-overlay :show="loading">
          <b-row>
            <b-col
              class="my-1"
              v-if="pb.checkout == 0 && !isFinance">
              <!-- v-if="pb.diterima == null && isAdminGudang && pb.selesai != 1" -->
              <b-button v-b-modal.modal-tambah variant="primary" @click="add()">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Rincian Barang Entertain
              </b-button>
              <b-modal
                v-model="showModal"
                id="modal-form"
                ok-title="Tutup"
                ok-variant="secondary"
                ok-only
                centered
                size="xl"
                title="Form "
                no-close-on-backdrop
                no-close-on-esc
              >
                <validation-observer ref="formbarang_entertain">
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19" />
                    <h6 class="ml-50 mb-2">Data Rincian Barang Entertain</h6>
                  </div>
                  <b-form>
                    <b-row>
                      <!-- item -->
                      <b-col sm="12">
                        <!-- Pilih Barang -->
                        <b-form-group>
                          <label><strong>Pilih Barang</strong></label>
                          <v-select
                            :options="barangs"
                            :reduce="(option) => option.value"
                            v-model="barang_id"
                            label="text"
                          />
                        </b-form-group>
                        <!-- / -->
                      </b-col>
                      <!-- qty -->
                      <b-col sm="12" md="4" v-if="barang_id">
                      <b-form-group>
                        <label for="jumlah">Jumlah</label>
                        <b-form-input
                          v-model="jumlah"
                          type="number"
                          id="jumlah"
                          placeholder="Jumlah"
                          @keyup="doFormatRupiah"
                          />
                          <!-- disabled -->
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4" v-if="barang_id">
                      <b-form-group>
                      <label for="konversi">Konversi</label>
                      <b-form-select
                        v-model="id_satuan"
                        :reduce="(option) => option.value"
                        :options="optkonversi"
                        id="konversi"
                        placeholder="konversi"
                      />
                    </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="barang_id">
                      <b-form-group>
                      <label for="nilai">Hasil Konversi</label>
                      <b-form-input
                        v-model="nilai"
                        type="number"
                        id="nilai"
                        placeholder="nilai"
                        @keyup="doFormatRupiah"
                      />
                    </b-form-group>
                    </b-col>
                  
                    <!-- <b-col md="6">
                      <b-form-group>
                        <label for="satuan">Satuan</label>
                        <b-form-input
                          v-model="satuan"
                          id="satuan"
                          placeholder="satuan"
                          disabled
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="12">
                    <label for="Penyimpanan">Pilih Penyimpanan</label>
                  <b-table
                    responsive
                    small
                    striped
                    bordered
                    :fields="fieldsPenyimpanan"
                    :items="penyimpanans"
                  >
                    <template #cell(action)="{ item }">
                      <b-form-radio
                        v-model="selectedBarang"
                        :value="item"
                        :name="`barang`"
                      />
                    </template>
                    
                  </b-table>
                  </b-col>
                      <!-- barang -->
                      <!-- <b-col cols="12">
                        <b-form-group label="Barang" label-for="v-barang">
                          <b-input-group
                            prepend="Rp."
                            class="input-group-merge"
                          >
                          <b-form-input 
                          v-model="form.barang"
                              class="form-control"
                              @keyup="doFormatRupiah"
                          /> -->

                            <!-- <cleave
                              id="number"
                              v-model="form.barang"
                              class="form-control"
                              :raw="false"
                              :options="options.number"
                              placeholder="10,000"
                            /> -->
                          <!-- </b-input-group>
                        </b-form-group>
                      </b-col> -->

                      <!-- submit and reset -->
                      <b-col cols="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          @click.prevent="submit"
                          variant="primary"
                          class="mr-1"
                          :disabled="!isValidForm"
                        >
                          Simpan
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="reset"
                          variant="outline-secondary"
                        >
                          Reset
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              >
              <!-- v-if="(pb.diterima == null || pb.diberikan == null) && pb.selesai == 0" -->
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                
                <template #cell(harga_dasar)="{ item }">
                  Rp. {{ formatRupiah(item.barang.harga_dasar) }}
                </template>
                <template #cell(jumlah)="{ item }">
                  Rp. {{ formatRupiah(item.qty * item.barang) }}
                </template>

                <template
                  #cell(actions)="row"
                  >
                  <!-- v-if="pb.diterima == null && pb.selesai == 0" -->
                  <!-- v-if="allowUpdate()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- v-if="allowDelete()" -->
                  <b-button
                  v-if="pb.checkout == 0  && !isFinance"
                    v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </main>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import vSelect from "vue-select";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BCard,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormRadio,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BFormRadio,
    vSelect,
    Cleave,
    BForm,
    BCard,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
    // barang_id(barang_id) {
    // },
    barang_id(barang_id) {
      this.getKonversi(barang_id);
      this.getPenyimpananBarang(barang_id)
    },
    jumlah(value2) {
      let hasil = value2 * this.id_satuan;
      this.nilai = hasil;
    },
    id_satuan(value) {
      let hasil = value * this.jumlah;
      this.nilai = hasil;
    },
  },
  data() {
    return {
      id_satuan: null,
      selectedBarang: null,
      satuan: null,
      optkonversi: [],
      nilai: 0,
      hasil: 0,
      konversi: 0,
      jumlah: 0,
      penyimpanan_id: "",
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      loading: false,
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        barang_entertain_id: null,
        qty: 0,
        penyimpanan_id:null,
      },
      form2: {
        id_akun: null,
        id_kas: null,
        // selesai : 1,
      },
      form3: {},
      form4: {
        checkout: 1,
      },
      formcancel: {
        checkout: 0,
      },
      formapprove: {
        mengetahui: 1,
      },
      formcancelapprove: {
        mengetahui: 0,
      },
      barangs:[],
      
      fieldsPenyimpanan: [
        { key: "action", label: "#" },
        // { key: "id", label: "id" },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "barang.nama", label: "Barang" },
        { key: "blok.blok", label: "blok" },
        { key: "palet.palet", label: "palet" },
        { key: "rak.rak", label: "rak" },
        { key: "laci.laci", label: "laci" },
        { key: "stok", label: "stok" },
        { key: "barang.satuan.satuan", label: "Satuan" },
      ],
      penyimpanans:[],
      barang_id: null,
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "tanggal",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      id_kas: [],
      id_akun: [],
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id_barang_entertain", label: "Kode", sortable: true },
        { key: "barang.nama", label: "Nama Barang", sortable: true },
        { key: "qty", label: "qty" },
        { key: "barang.satuan.satuan", label: "Satuan" },
        // { key: "harga_dasar", label: "harga dasar" },
        { key: "actions", label: "Aksi" },
      ],
      
      items: [],
      pb: {
        tanggal: null,
        keterangan: null,
        status: null,
        tipe: null,
        checkout: null,
        diterima: null,
        id_kas: null,
      },
      showModalapprfinance: false,
      formcancelakun:{
        id:null,
      },
    };
  },
  computed: {
    invalidAkun() {
      const akun = this.id_akun.find(item => item.id == this.form2.id_akun)
      if(!akun) return false

      return akun && (parseInt(akun.saldo) < 1)
    },
    invalidKas() {
      const kas = this.id_kas.find(item => item.id == this.form2.id_kas)
      if(!kas) return false

      return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < parseInt(this.pb.total)) 
    },
    isValidAkunForm() {
      if(!this.form2.id_akun || !this.form2.id_kas) {
        return false
      }

      // get selected akun and kas and validate saldo
      if(this.invalidKas) {
        return false
      }

      return true

    },
    isValidForm() {
      if(!this.selectedBarang || !this.jumlah || !this.id_satuan) {
        return false
      }

      return true
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsDetail
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getData();
    this.getPb();
    this.getBarangs();
    this.doFormatRupiah();
    // this.getStatus();
  },
  methods: {
    async getBarangs() {
      const barangs = await this.$store.dispatch("barang/getDataV2",{jenis: this.pb.jenis});
      barangs.map((barang) => {
        this.barangs.push({
          value: barang.id,
          text: barang.nama,
        });
      });
    },
    async getPenyimpananBarang(barang_id) {
      let params = {
        barang_id: barang_id,
      };
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id;
      }
      await this.$store
        .dispatch("penyimpanan/getData", params)
        .then(() => {
          this.penyimpanans = this.$store.state.penyimpanan.datas;
          this.totalRows = this.penyimpanans.length;
        })
        .catch((e) => console.error(e));
    },
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
      this.id_satuan = 1
    },
    doFormatRupiah() {
      this.jumlah = this.formatRupiah( this.jumlah )
      this.nilai = this.formatRupiah( this.nilai )
    },
    confirmapprove() {
      this.$swal({
        title: 'Anda yakin?',
        text: "Approve barang entertain ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.approve();
          this.$swal({
            icon: 'success',
            title: 'Approve',
            text: 'Anda telah approve barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan approve barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    confirmcancelapprove() {
      this.$swal({
        title: 'Anda yakin?',
        text: "Cancel approve barang entertain ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cancelapprove();
          this.$swal({
            icon: 'success',
            title: 'Cancel Approve',
            text: 'Anda telah cancel approve barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan cancel approve barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    confirmcancel() {
      this.$swal({
        title: "Anda yakin?",
        text: "Cancel/Batal checkout barang entertain sekarang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cancel();
          this.$swal({
            icon: 'success',
            title: 'Checkout',
            text: 'Anda telah cancel checkout barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan cancel checkout barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    
    confirmcanceljurnal() {
      this.$swal({
        title: "Anda yakin?",
        text: "Cancel akun dan kas untuk barang entertain sekarang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.canceljurnal();
          this.$swal({
            icon: 'success',
            title: 'Checkout',
            text: 'Anda telah cancel akun dan kas untuk barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan cancel akun dan kas untuk barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    confirmselesai() {
      this.$swal({
        title: "Anda yakin?",
        text: "Checkout barang entertain sekarang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.selesai();
          this.$swal({
            icon: 'success',
            title: 'Checkout',
            text: 'Anda telah selesai checkout barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan checkout barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    confirmditerima() {
      this.$swal({
        title: "Anda yakin?",
        text: "Sudah terima Barang Pengambilang Barang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        this.diterima();
      });
    },
    confirmdiberikan() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_akun = akun.filter(item => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async diterima() {
      if (this.$route.params.id) {
        this.form3.id = this.$route.params.id;
      }
      const payload = this.form3;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/terima", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain telah diterima",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async diberikan() {
      if(!this.isValidAkunForm) {
        this.displayError({
          message: 'Harap lengkapi form pemilihan akun dengan benar!'
        })
        return false
      }
      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/jurnal", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.resetForm();
          this.displaySuccess({
            message: "Barang entertain telah dimasukkan ke akun",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async cancelapprove() {
      if (this.$route.params.id) {
        this.formcancelapprove.id = this.$route.params.id;
      }
      const payload = this.formcancelapprove;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/approve", [payload]);
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain sudah di cancel approve owner",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    
    async canceljurnal() {
      if (this.$route.params.id) {
        this.formcancelakun.id = this.$route.params.id;
      }
      const payload = this.formcancelakun;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/cancel", [payload]);
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain sudah di cancel akun dan kas oleh finance",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async approve() {
      if (this.$route.params.id) {
        this.formapprove.id = this.$route.params.id;
      }
      const payload = this.formapprove;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/approve", [payload]);
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain sudah di approve owner",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async selesai() {
      if (this.$route.params.id) {
        this.form4.id = this.$route.params.id;
      }
      const payload = this.form4;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/checkout", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain sudah di checkout",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async cancel() {
      if (this.$route.params.id) {
        this.formcancel.id = this.$route.params.id;
      }
      const payload = this.formcancel;
      try {
        const ajuan = await this.$store.dispatch("barang_entertain/checkout", [payload]);
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Barang entertain sudah di cancel checkout",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async getPb() {
      const pb = await this.$store.dispatch(
        "barang_entertain/getDataById",
        this.$route.params.id
      );
      this.pb = pb;
    },
    async getBarangPb() {
      const barangs = await this.$store.dispatch("barang_entertain/getData", {
        barang_entertain_id: this.$route.params.id,
      });
      this.barangs = barangs;
    },
    resetForm() {
      this.form.id = null;
        // this.form.barang_entertain_id= null,
        this.form.qty= 0,
        this.form.penyimpanan_id=null,
        this.selectedBarang=null,
        this.penyimpanans=null,
        this.barang_id=null,
      this.showModalapprfinance = false;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.showModalapprfinance = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Barang rincian barang entertain ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.selesai();
          item.fungsi = 1; // soft delete
        this.$store
          .dispatch("barang_entertain/save2", [item])
          .then(() => {
            this.getData();
            this.displaySuccess({
              message: "Barang rincian barang entertain ini berhasil dihapus",
            });
            this.resetForm();
          })
          this.$swal({
            icon: 'success',
            title: 'Berhasil',
            text: 'Anda telah berhasil hapus rincian barang entertain ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan hapus rincian barang entertain',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    
    submit() {
      this.$refs.formbarang_entertain.validate().then(async (success) => {
        if (success) {

          if(!this.selectedBarang) {
            this.displayError({
              message: 'Harap pilih letak barang!'
            })
            return false
          }
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.barang_entertain_id = this.$route.params.id;
          this.form.penyimpanan_id = this.selectedBarang.id;
          this.form.qty = this.unFormatRupiah( this.nilai )
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("barang_entertain/save2", [
              payload,
            ]);
            this.label = "Submit";
            this.getData();
            this.resetForm();
            this.$bvModal.hide('modal-form')

            this.displaySuccess({
              message: "Rincian barang entertain telah disimpan",
            });
          } catch (e) {
            this.$bvModal.show('modal-form')
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      // let params = this.isAdminGudang ? { id_barang_entertain: this.myGudang.id } : {};

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("barang_entertain/getData2", {
        barang_entertain_id: this.$route.params.id,
      });
      this.loading = false;
      this.items = ajuans;
      // this.items.barang = formatRupiah(ajuans.barang);
      this.totalRows = ajuans.length;
    },
    // getid_gudang() {
    //   this.$store
    //     .dispatch("karyawan/getData", { jabatan_id: 5 })
    //     .then(() => {
    //       let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
    //       karyawan.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama_lengkap;
    //       });

    //       this.id_gudang = karyawan;
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
    // getsalesName(data) {
    //   return data.sales ? data.sales.nama_lengkap : "-";
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
